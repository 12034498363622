<template>
  <div :class="{ contain: true, mobile: isMobile }">
    <div class="card_img mb-5">
      <img
      v-if="pageData.fileType!= 'MP4'"
        class="sizeimg mb-n5"
        :src="pageData.imgUrl"
        alt=""
        @click="openLargeImg"
      />
      <video
           v-else
           class="video"
           ref="video"
           autoplay
            controls
            controlslist="nodownload"
           loop
           muted
           :src="pageData.fileUrl"
           @click="openLargeImg"
         ></video>
    
      <!-- <div class="status">
            <img v-if="pageData.denomStatus == '3'" class="ban" src="@/assets/icons/ban.png" alt="" />
            <img v-else-if="pageData.saleStatus == '1'" src="@/assets/icons/icon_onsale.png" alt="Onsale" />
         </div> -->
    </div>
    <div class="card_info d-flex flex-column mt-lg-15" style="width: 100%">
      <div class="cirtile">
        <div class="d-flex flex-row justify-space-between sharestyle">
          <div class="tit">{{ pageData.name }}</div>
          <img
            width="20px;"
            height="20px;"
            src="@/assets/icons/icon_share.png"
            @click.stop="shareBtn"
            alt=""
          />
          <div class="share" v-if="openShare">
            <div
              class="d-flex flex-row mt-lg-5 mt-5 pointer"
              @click="share('copyLink', '', '')"
            >
              <img
                class="mx-5"
                src="@/assets/icons/icon_IPFS.png"
                alt="Copy Link"
              />
              <span class="ml-0">{{ $t("carddetailShareCopyLink") }}</span>
            </div>
            <div
              class="d-flex flex-row mt-lg-6 mt-6 pointer"
              @click="share('twitter', sharePath, '')"
            >
              <img
                class="mx-5"
                src="@/assets/icons/icon_twitter.png"
                alt="Tweet"
              />
              <span class="ml-0">Twitter</span>
            </div>
            <div
              class="d-flex flex-row mt-lg-6 mb-lg-4 mt-6 mb-4 pointer"
              @click="share('facebook', sharePath, '')"
            >
              <v-icon class="mx-lg-5 ml-5 icon" color="primary">
                mdi-facebook
              </v-icon>
              <span class="ml-lg-0 ml-6 pr-7">Facebook</span>
            </div>
          </div>
        </div>

        <div class="cont d-flex flex-wrap" v-html="pageData.description"></div>
        <div class="title mt-7" v-if="PropertyList&&PropertyList.length>0">{{ $t("Properties_title")}}</div>
        <div v-for="(item,index) in PropertyList" :key="index" class="d-flex flex-column">
          <div class="d-flex flex-row">
            <div class="Properties">{{item.trait_type}}</div>
             <div class="PropertiesValue " > {{item.value}}</div>
          </div>
        </div>
        <div class="lincenseimg mt-4" v-if="pageData.lincenseCode == 'CC0'">
      <img src="@/assets/icons/cc-zero.png" @mouseenter="mousedlincense()" @mouseleave="leavelincense()"
                alt="" />
      <div class="explainlincense d-flex flex-row align-center" v-if="isshowLincense" >
                <span>{{$t("CC0_explain")}} </span>
              </div>
    </div>

    
        
        <div class="mt-8 mb-2 mr-0 px-0 link d-flex flex-wrap NftID">
          NFT ID: {{ $route.query.tokenId }}
        </div>

        	<div class="  d-flex flex-center createName " style="margin-top:-4px; position: relative;">
					 {{ $t("Contract") }} <a :href="this.addressExploer" target="_blank" class="name" style="text-decoration: none;">{{ pageData.contractAddress }}</a>		
						<img v-if="pageData.disabled==1" style="height:20px;width:20px;margin-left:10px;" src="@/assets/icons/officially_verified.png" alt=""  @mouseenter="mousedContract()" @mouseleave="leaveContract()"/>
             <div
           		 class="explaincontract d-flex flex-row align-center"
                v-if="isshowContract"
         			 >
          		  <span
            			  >{{ $t("Officially")}}
           		 </span>
          		</div>
				</div>
<div v-if=" pageData.channel == 'IRISnet'">
  <div class="createName">
          {{$t("created_by")}}<span @mouseenter="mousedCreate()">  {{ pageData.creatorName ? pageData.creatorName:pageData.creatorOwner.substr(0,6) + "..." + pageData.creatorOwner.substr(-6) }}</span>
        </div>
          <div style="position:absolute;margin-left:90px;z-index:1">
          <CreatorAvator  :did="pageData.creatorOwner"  v-if="isShowAva"/>
        </div>
        
</div>
<div v-else>
  <div class="createName">
    {{$t('originally_by')}} <span @click="toMarketplacePage(pageData.creator)"> {{pageData.creator.substr(0,6) + "..." + pageData.creator.substr(-6)}}   </span>  {{$t('originally_on')}} {{pageData.channel}}
        </div>
       
        
</div>
        
        <div class="d-flex flex-row views mt-3 mb-8">
          <img src="@/assets/icons/icon_IPFS.png" alt="IPFS" />
          <a class="ml-4 mt-1" :href="IpfsHub" target="_blank">{{
            $t("saledetailViewOnIpfs")
          }}</a>
          <img src="@/assets/icons/icon_metadata.png" class="ml-10" alt="IPFS" />
          <a class="ml-4 mt-1" :href="IpfsMetadata" target="_blank">{{$t("view")}} Metadata</a>
        </div>
        
        <div class="d-flex flex-row"  v-if="pageData.listAmount != 0 && pageData.amount > 0 && pageData.useType != '2'">
             <!-- <div class="avaiable" v-if="pageData.useType == 5  &&  pageData.couponTime"> {{$t("saledetailHistoryPrice")}} {{ pageData.couponTime.deduction  }} {{ pageData.tokenType }} <span class="irisOriginalPric" >	{{ pageData.marketPrice  }} {{ pageData.tokenType }}</span></div>
          <div class="avaiable" v-else > {{$t("saledetailHistoryPrice")}} {{ pageData.onsalePrice }} {{ getChainNameByDenom(pageData.coinDenom)}} </div> -->
         
          <!-- <span class=" avaiable ">{{$t("saledetailAvaiable")}} {{ pageData.amount }}</span>  -->
                <div class="preferentialInfo d-flex flex-row" v-if="pageData.useType == '5' &&  pageData.couponTime">
			<div class="img">
				<img src="@/assets/icons/icon_time.png"  width="40px"
            height="40px" alt="">
			</div>
			<div class="contant">
        			<div class="des">{{$t('LimitedTime')}} </div>
				<div class="time">{{startTimer}} ~ {{endTimer}}</div>
			
			</div>
		</div>

        </div>
        <div class="d-flex flex-row"  v-if="pageData.listAmount == 0 && pageData.amount > 0 && pageData.useType != '2'">
         
          <!-- <div class="avaiable">{{$t("saledetailAvaiable")}} {{ pageData.amount }}</div>  -->
            <div class="preferentialInfo d-flex flex-row" v-if="pageData.useType == '5'">
			<div class="img">
				<img src="@/assets/icons/icon_time.png"  width="40px"
            height="40px" alt="">
			</div>
			<div class="contant">
        			<div class="des">{{$t('LimitedTime')}}</div>
				<div class="time">{{startTimer}} ~ {{endTimer}}</div>
	
			</div>
		</div>

        
        </div>
        
        
     
        <div class="footor d-flex flex-row justify-space-between mt-5">

          <div class="sub">
   
            <template v-if="pageData.disabled >=2">
            <!-- 被处理的资产 start-->
            <div v-if="pageData.disabled==4" class="d-flex justify-space-between">
              <div v-if="pageData.creatorOwner != this.$store.state.did" class="d-flex flex-row">
                <img class="mt-n2 ban" src="@/assets/icons/ban.png" width="20px" height="20px" alt=""
              @mouseenter="moused()" @mouseleave="leave()" />
              <div class="explain d-flex flex-row align-center" v-if="show">
              <span>{{$t("restricted_nft")}}</span>
              </div>
            </div>
              <div v-else>
                <div class="mt-n2 text-wrap text--info text--secondary-rd">
                          {{ $t("workCardSuspected") }}
                        </div>
                        <v-btn class="haveAppeal mt-3 ml-3" dark rounded width="100" height="28">
                          {{ $t("headerUserCenterNavAppealed") }}
                        </v-btn>
              </div>
                        
                      </div>
                      <div v-else class="d-flex justify-space-between">
                        <!-- <div class="mt-1 text-wrap text--info text--secondary-rd">
               {{ $t("workCardSuspected") }}
            </div> -->
                        <img class="mt-n2 ban" src="@/assets/icons/ban.png" width="20px" height="20px" alt=""
                          @mouseenter="moused()" @mouseleave="leave()" />
                          
                        <v-btn v-if="pageData.disabled==2&&pageData.creatorOwner == this.$store.state.did" class="sale ml-4" dark rounded width="100"
                          height="28" @click="AppealBtn">{{ $t("workCardAppeal") }}
                        </v-btn>
                        <div class="explain d-flex flex-row align-center" v-if="show">
                          <span>{{$t("restricted_nft")}}</span>
                        </div>
                      </div>
            <!-- 被处理的资产 end-->  
            </template>
              <template v-else>
             <div v-if="pageData.useType == '0' || pageData.useType == null || pageData.useType == '3' || pageData.useType == '4' || pageData.useType == '5'">
                       <!-- 进行中 -->
      <template v-if="pageData.saleStatus == '6' || pageData.saleStatus == '7' || pageData.saleStatus == '8' || pageData.saleStatus == '9' ">
        <div
          class="d-flex justify-center align-center pt-1 text--info text--secondary-rd"
        >
          {{ $t("workCardInprogress") }}
        </div>
      </template>
                  <!-- 已上架，显示下架按钮 -->
            <div v-else-if="pageData.onsaleTokenIds.length > 0">
              <v-btn class="sale" dark rounded depressed width="105" height="40" @click="EndSaleBtn"
                >{{ $t("popupSoldoutEndsale") }}
              </v-btn>
            </div>

              <!-- 已下架，显示上架按钮 -->
        <div v-else-if="pageData.offsaleTokenIds.length > 0">
          <div class="d-flex justify-space-between" style="margin-top: -8px">
           
            <!-- 收藏部分的上架按钮  替换为 上架按钮 -->
            <div>
              <v-btn
                class="satrtsale uc"
                dark
                rounded
                depressed
                width="106"
                height="41"
                @click="putOnSaleBtn"
                >{{ $t("popupMarketStartSale") }}
              </v-btn>
            </div>

             <div class="btnbox ml-3">
              <v-btn class="rd" rounded @click="TransferBtn"
                >{{ $t("popupNewTransferTransfer") }}
              </v-btn>
            </div>
            <!-- 跨链 -->
              <div class="btnbox ml-3">
              <v-btn class="rd" rounded @click="CrossChainBtn"
                >{{ $t("cross_transfer") }}
              </v-btn>
            </div>
           
          </div>
        </div>
    
             </div>
             <div v-if="pageData.useType == '1'">
                  <!-- 活动未开始 -->
        <div v-if="pageData.auctionVo.auctionStatus == '0'">
          <div class="d-flex flex-row auction-status">
            <div>
              <div class="text--sub-title text--secondary-rd">
                {{ $t("popupAuctionStartingBid") }} <span style="font-size:15px;font-weight:bold;">{{pageData.reservePrice}} {{getChainNameByDenom(pageData.coinDenom)}}</span>
              </div>
            </div>

            <div class="d-flex flex-row">
              <div class="text--sub-title text--secondary-rd ml-6">
                {{ $t("popupAuctionStartDate") }}
              </div>
              <div class="ml-2">
                <div class="text--number text--primary-rd font-weight-medium">
                  {{ hour }}h {{ minutes }}m {{ second }}s
                </div>
              </div>
            </div>

              <div class="ml-6" style="font-size:15px;font-weight:bold;">
            
                  {{ $t("saledetailAvaiable") }}  {{pageData.amount}}
              </div>

            
          </div>
           <v-btn
                class="withdraw"
                dark
                rounded
                depressed
                width="106"
                height="41"
                @click="AbanBtn"
                
                >{{ $t("Withdraw") }}
              </v-btn>
        
        </div>
         <!-- 拍卖开始 -->
        <div
          v-if="
            (pageData.auctionVo.auctionStatus == '1') ||
            pageData.auctionVo.auctionStatus == '3'
          "
        >
          <div class="d-flex flex-row auction-status">
            <div class="d-flex flex-row">
              <div class="text--sub-title text--secondary-rd">
                {{ $t("workCardCurrentBid") }}
              </div>
                <div
                  class="ml-2 text--number text--primary-rd font-weight-medium"
                  style="line-height: 22px"
                >
                 {{pageData.auctionVo.lastBidPrice}} {{getChainNameByDenom(pageData.auctionVo.coinDenom)}}
                </div>
            </div>
            <div class="d-flex flex-row">
              <div class="text--sub-title text--secondary-rd ml-6">
                {{ $t("workCardEndingin") }}
              </div>
              <div class="ml-2">
                <div class="text--number text--primary-rd font-weight-medium">
                  {{ hour }}h {{ minutes }}m {{ second }}s
                </div>
              </div>

            </div>  
              <div class="ml-6" style="font-size:15px;font-weight:bold;">
                  {{ $t("saledetailAvaiable") }}  {{pageData.amount}}  
              </div>
            
          </div>
          <v-btn
                class="withdraw"
                dark
                rounded
                depressed
                width="106"
                height="41"
                @click="AbanBtn"
                v-if="pageData.auctionVo.lastBidPrice != null &&  !pageData.auctionVo.hasBid "
                >{{ $t("Withdraw") }}
              </v-btn>
        
        </div>
       
    </div>
<template v-if="pageData.useType == '6'">
    <template >

        <div
         v-if="pageData.saleStatus == '6' || pageData.saleStatus == '7' || pageData.saleStatus == '8' || pageData.saleStatus == '9' "
          class="d-flex justify-center align-center pt-1 text--info text--secondary-rd"
        >
          {{ $t("workCardInprogress") }}
        </div>
   
      <!-- <div v-else>
        <v-btn
            class="sale mb-5"
            dark
            rounded
            depressed
            width="105"
            height="40"
            @click="WithdrawBtn"
            >{{ $t("Withdraw") }}
          </v-btn>
          <img src="@/assets/icons/icon_code.png"  width="40px" 
            height="40px" class="ml-5" alt=""  @click="showQRcode">
      </div> -->
          
        </template>
      </template>
            <!-- 拍卖完了 -->
        <div v-if="pageData.amount == 0">
          <div class="d-flex flex-row mb-6"  v-if="pageData.issuerPrice != null ">
            <div class="text--sub-title text--secondary-rd">
              {{ $t("Price") }} 
            </div>
           
              <div
                class="ml-2 text--number text--primary-rd font-weight-medium"
                style="line-height: 22px"
              >
                {{ pageData.issuerPrice }} {{pageData.issuerTokenType}}
              </div>
          
          </div>
       
          <div class="auction_out" style="font-size:15px;font-weight:bold;">{{ $t("workCardSoldOut") }}</div>
        </div>
          
  </template>
          </div>
          <div class="burn">
            <div class="d-flex flex-row align-center">
              <!-- 销毁弹层按钮 -->
              <img
                class="ml-5"
                src="@/assets/icons/icon_burn.png"
                width="20px"
                height="20px"
                 v-if="pageData.isBurn == true"
                alt=""
          @click.stop="burnBtn"
              />
              <!-- 销售统计弹层按钮 -->
              <img
                class="ml-5"
                src="@/assets/icons/icon_data.png"
                width="20px"
                height="20px"
                alt=""
                v-if="pageData.creatorOwner == this.$store.state.did"
                @click="saleTotalBtn"
              />
            </div>
          </div>
        </div>
      </div>
     
    </div>

    <!-- 跨链记录 -->
    <div class="table">
      <div class="mb-7 mt-7 tab_tit">{{ $t("cross_transfer") }}</div>
      <v-data-iterator
        v-if="crossChianList.length > 0"
        class="table_cont"
        :items="crossChianList"
      >
        <template v-slot:header>
          <div
            class="table_font d-flex justify-space-around mb-1 mt-4 py-3 px-lg-10 elevation-0"
            style="border: 1px solid #cccccc;border-radius: 5px;"
          >
            <div style="width: 21%; font-weight: bold">
              {{ $t("saledetailHistoryForm") }}
            </div>
            <div style="width: 42%; font-weight: bold">
              {{ $t("saledetailHistoryTo") }}
            </div>
          
            <!-- <div style="width: 21%;font-weight:bold;">
                     {{ $t("saledetailHistoryTranscation") }}
                  </div> -->
            <div style="width: 21%; font-weight: bold">
              {{ $t("detailIndexTime") }}
            </div>
          </div>
        </template>
        <template v-slot:default="{ items }">
          <div
            class="table_item_font d-flex justify-space-around mb-1 py-2 elevation-0"
            style="border: 1px solid #cccccc;border-radius: 5px;"
            v-for="(item) in items"
            :key="item.id"
          >
            <div style="width: 20%;font-size:14px;" class="d-flex flex-row align-center item_font ml-lg-12 mr-1 pt-lg-1">
              <img  :src="item.fromChainIcon"  width="18" height="18" alt="">
              <span class="ml-3 item_font item_fonts" >{{ item.fromChainType }}</span>
            </div>
            <div style="width: 42%;;font-size:14px;" class="d-flex flex-row align-center mr-1 fom">
              <img  :src="item.toChainIcon"  width="18" height="18" alt="">
              <span class="ml-3 item_font item_fonts" style="">{{ item.toChainType }}</span>
            </div>
          
            <div style="width: 20%;font-size: 14px;margin-top:5px;" class="item_font mr-10">
              {{ timestampToDateTime( Number(item.timespan) )}}
            </div>
          </div>
        </template>
      </v-data-iterator>
      <v-data-iterator
        v-else
        class="table_cont"
        :items="crossChianList"
        hide-default-footer
      >
        <template v-slot:header>
          <div
            class="table_font d-flex justify-space-around mb-1 mt-8 py-3 px-lg-10 elevation-1"
          >
            <div style="width: 21%; font-weight: bold">
              {{ $t("saledetailHistoryForm") }}
            </div>
            <div style="width: 42%; font-weight: bold">
              {{ $t("saledetailHistoryTo") }}
            </div>
         
            <div style="width: 21%; font-weight: bold">
              {{ $t("detailIndexTime") }}
            </div>
          </div>
        </template>
        <template v-slot:default="{ items }">
          <div
            class="table_item_font d-flex justify-space-around mb-1 py-2 elevation-1"
            v-for="item in items"
            :key="item.time"
          >
            <div style="width: 20%" class="item_font ml-lg-7 mr-1">
              <img  :src="item.fromChainIcon"  width="18" height="18" alt="">
              <span class="ml-3 item_font item_fonts" style="margin-top:5px;">{{ item.fromChainType }}</span>
            </div>
            <div style="width: 42%" class="d-flex flex-row mr-1 fom">
              <img  :src="item.toChainIcon"  width="18" height="18" alt="">
              <span class="ml-3 item_font item_fonts" style="margin-top:5px;">{{ item.toChainType }}</span>
            </div>
            <div style="width: 20%" class="item_font mr-10">
              {{ timestampToDateTime( Number(item.timespan) )}}
            </div>
          </div>
        </template>
      </v-data-iterator>
    </div>

    <!-- 历史记录 -->
    <div class="table">
      <div class="mb-7 mt-7 tab_tit">{{ $t("saledetailHistory") }}</div>
      <v-data-iterator
        v-if="desserts.length > 0"
        class="table_cont"
        :items="desserts"
      >
        <template v-slot:header>
          <div
            class="table_font d-flex justify-space-around mb-1 mt-4 py-3 px-lg-10 elevation-0"
            style="border: 1px solid #cccccc;border-radius: 5px;"
          >
            <div style="width: 21%; font-weight: bold">
              {{ $t("saledetailHistoryEvent") }}
            </div>
            <div style="width: 21%; font-weight: bold">
              {{ $t("saledetailHistoryForm") }}
            </div>
            <div style="width: 21%; font-weight: bold">
              {{ $t("saledetailHistoryTo") }}
            </div>
            <!-- <div style="width: 21%;font-weight:bold;">
                     {{ $t("saledetailHistoryTranscation") }}
                  </div> -->
            <div style="width: 21%; font-weight: bold">
              {{ $t("detailIndexTime") }}
            </div>
          </div>
        </template>
        <template v-slot:default="{ items }">
          <div
            class="table_item_font d-flex justify-space-around mb-1 py-2 elevation-0"
            style="border: 1px solid #cccccc;border-radius: 5px;"
            v-for="(item) in items"
            :key="item.id"
          >
            <div style="width: 20%;font-size:14px;" class="item_font ml-lg-12 mr-1 pt-lg-1">
              {{ item.event }}
            </div>
            <div style="width: 20%;;font-size:14px;" class="d-flex flex-row mr-1 fom">
              <v-avatar size="30" style="overflow:visible; position: relative;">
                <v-img :src="item.formavatarSrc" :alt="item.form" />
                   <img
                v-if="item.fromGrade==3"
                src="@/assets/icons/icon_v.png"
                alt="V"
                style=" width:15px;height:15px;position: absolute; right: -4px;bottom: 1px;"
                  />
              </v-avatar>
              <span class="ml-3 item_font item_fonts" style="margin-top:5px;">{{ item.form }}</span>
            </div>
            <div style="width: 22%" class="d-flex flex-row mr-1 toto">
              <v-avatar size="30" style="overflow:visible; position: relative;">
                <v-img :src="item.toavatarSrc" :alt="item.to" />
                  <img
                v-if="item.toGrade==3"
                src="@/assets/icons/icon_v.png"
                alt="V"
                style=" width:15px;height:15px;position: absolute; right: -4px;bottom: 1px;"
                  />
              </v-avatar>
              <span class="ml-3 item_font item_fonts" style="font-size: 14px;margin-top:5px;">{{ item.to }}</span>
            </div>
            <!-- <div style="width: 20%" class="item_font mr-1">
                     {{ item.transcation }}
                  </div> -->
            <div style="width: 20%;font-size: 14px;margin-top:5px;" class="item_font mr-10">
              {{ item.time }}
            </div>
          </div>
        </template>
      </v-data-iterator>
      <v-data-iterator
        v-else
        class="table_cont"
        :items="desserts"
        hide-default-footer
      >
        <template v-slot:header>
          <div
            class="table_font d-flex justify-space-around mb-1 mt-8 py-3 px-lg-10 elevation-1"
          >
            <div style="width: 21%; font-weight: bold">
              {{ $t("saledetailHistoryEvent") }}
            </div>
            <div style="width: 21%; font-weight: bold">
              {{ $t("saledetailHistoryForm") }}
            </div>
            <div style="width: 21%; font-weight: bold">
              {{ $t("saledetailHistoryTo") }}
            </div>
            <!-- <div style="width: 21%;font-weight:bold;">
                     {{ $t("saledetailHistoryTranscation") }}
                  </div> -->
            <div style="width: 21%; font-weight: bold">
              {{ $t("detailIndexTime") }}
            </div>
          </div>
        </template>
        <template v-slot:default="{ items }">
          <div
            class="table_item_font d-flex justify-space-around mb-1 py-2 elevation-1"
            v-for="item in items"
            :key="item.time"
          >
            <div style="width: 20%" class="item_font ml-lg-7 mr-1">
              {{ item.event }}
            </div>
            <div style="width: 20%" class="d-flex flex-row mr-1 fom">
              <v-avatar size="30">
                <v-img :src="item.formavatarSrc" :alt="item.form" />
              </v-avatar>
              <span class="ml-3 item_font item_fonts">{{ item.form }}</span>
            </div>
            <div style="width: 22%" class="d-flex flex-row mr-1 toto">
              <v-avatar size="30">
                <v-img :src="item.toavatarSrc" :alt="item.to" />
              </v-avatar>
              <span class="ml-3 item_font item_fonts">{{ item.to }}</span>
            </div>
            <!-- <div style="width: 20%" class="item_font mr-1">
                     {{ item.transcation }}
                  </div> -->
            <div style="width: 20%" class="item_font mr-10">
              {{ item.time }}
            </div>
          </div>
        </template>
      </v-data-iterator>
    </div>

    <div
      v-if="openmsg"
      style="position: fixed; right: 20px; bottom: 20px; z-index: 99"
    >
      <message @getOpen="OpenMsg" :currfriend="currfriend"></message>
    </div>
   <!-- 转送/新建转送 -->
    <v-dialog v-model="Transfer">
      <new-transfer
        :imgUrl="sharePath"
        :address="address"
        :level="1"
        :name="pageData.name"
        :contracId="pageData.id"
        :offSaleTokenIds="offSaleTokenIds"
        :parentValue="pageData"
        source="carddetailTransfer"
        @getOpen="opentransfer"
      ></new-transfer>
    </v-dialog>
    <v-snackbar
      v-model="isShowFalse"
      color="error"
      centered
      timeout="2000"
      style="opacity: 0.8"
      ><span style="color: white; font-size: 20px">{{
        falseTitle
      }}</span></v-snackbar
    >
    <v-snackbar
      v-model="isShowSuccess"
      color="success"
      centered
      timeout="2000"
      style="opacity: 0.8"
      ><span style="color: white; font-size: 20px">{{
        successTitle
      }}</span></v-snackbar
    >
    <v-snackbar
      v-model="isShowDelistFalse"
      color="error"
      centered
      timeout="2000"
      style="opacity: 0.8"
      ><span style="color: white; font-size: 20px">{{
        delistFalseTitle
      }}</span></v-snackbar
    >
    <v-snackbar
      v-model="isShowDelistSuccess"
      color="success"
      centered
      timeout="2000"
      style="opacity: 0.8"
      ><span style="color: white; font-size: 20px">{{
        delistSuccessTitle
      }}</span></v-snackbar
    >

    <v-dialog fullscreen v-model="openLargemg">
      <large-img
        ref="largeDialog"
        v-if="openLargemg"
        :productPic="pageData.imgUrl"
        @getOpen="closeLargeImg"
      ></large-img>
    </v-dialog>
      <!-- 结束售卖 -->
    <v-dialog v-model="Sold">
      <soldout
       :address="address"
        :onSaleTokenIds="onSaleTokenIds"
        :offSaleTokenIds="offSaleTokenIds"
        :name="pageData.name"
        :parentValue="pageData"
        @getOpen="openSold"
      ></soldout>
    </v-dialog>
     <!-- 新增 上架选择 -->
    <v-dialog v-model="openputonsale">
      <put-on-sale
        @getOpen="openPutOnSale"
        :address="address"
        :onSaleTokenIds="onSaleTokenIds"
        :offSaleTokenIds="offSaleTokenIds"
        :supplyLimit="supplyLimit"
        :name="name"
        :parentValue="pageData"
      
        v-bind="$attrs"
        v-on="$listeners"
      ></put-on-sale>
    </v-dialog>
      <!-- 未达到目的  放弃   / 及 撤销 使用  -->
    <v-dialog v-model="openaban">
      <auction-aban
        :auctionNo="auctionNo"
        :auctionStatus="auctionStatus"
        :parentValue="pageData"
        @getOpen="openAban"
      ></auction-aban>
    </v-dialog>
     <v-dialog v-model="Total">
      <saletotal
        :pageData="pageData"
        :address="address"
        :id="pageData.id"
        :name="pageData.name"
        :minPrice="pageData.minPrice"
        @getOpen="openTotal"
      ></saletotal>
    </v-dialog>
  <!-- 销毁 -->
    <v-dialog v-model="Burning">
      <burn :address="address"  :name="pageData.name" @getOpen="openBurn" :pageValue="pageData"></burn>
    </v-dialog>
           <!-- 被投诉提醒框 -->
    <v-dialog v-model="Banprompt">
      <banprompt v-if="Banprompt"  @getOpenBan="openBanprompt"></banprompt>
    </v-dialog>
    <!-- 申诉 -->
    <v-dialog v-model="Appeal">
      <nftAppeal  :parentValue="pageData" appealType='commonAppeal' @getOpen="openAppeal">
      </nftAppeal>
    </v-dialog>
      <!-- <v-dialog v-model="OpenQrCode">
      <receiveQrCode v-if="OpenQrCode"  @getOpenQRcode="openQrCode" :Nft_sku="pageData"></receiveQrCode>
    </v-dialog>
     <v-dialog v-model="OpenWithDraw">
      <withDraw v-if="OpenWithDraw"  @getOpen="OpenWithDraws"  :parentValue="pageData"  :name="pageData.name"   ></withDraw>
    </v-dialog> -->
     <!-- 跨链 crossChainTransfer-->
   <v-dialog v-model="crossChain">
      <CrossChainTransfer   :parentValue="pageData" @getOpen="OpenCrossChain"></CrossChainTransfer>
    </v-dialog>
      <!-- 跨链成功提示层 -->
      <v-dialog v-model="crossSuccess">
      <CrossSuccess   :parentValue="pageData" @getOpen="OpenCrossSuccess"></CrossSuccess>
    </v-dialog>
  </div>
</template>

<script>

import LargeImg from "../../components/largeImg";
import api from "@/api";
import { getFileSrc } from "@/utils/file";
import { share } from "@/utils";
import logoimg from "@/assets/default.png";
import Soldout from "@/components/popup/soldout.vue";
import PutOnSale from "@/components/popup/putOnSale.vue";
import AuctionAban from "@/components/popup/auctionAban.vue";
import Saletotal from "@/components/popup/saletotal.vue";
import CreatorAvator from "@/components/creatorAvatar/index";
import Burn from "@/components/popup/burn.vue";
import newTransfer from "@/components/popup/newTransfer.vue";
import Banprompt from "@/components/popup/banprompt.vue";
import NftAppeal from "@/components/popup/nftAppeal.vue";
import CrossChainTransfer from "@/components/popup/crossChainTransfer.vue";
import CrossSuccess from "@/components/popup/crossSuccess.vue";
//  import receiveQrCode from "@/components/popup/receiveQrCode.vue";
//   import withDraw from "@/components/popup/withDraw.vue";

const BANNER_KEY = "BANNER";

export default {

  components: {   LargeImg,Soldout,PutOnSale,AuctionAban,Saletotal,CreatorAvator,Burn,newTransfer,Banprompt,NftAppeal,CrossChainTransfer,CrossSuccess},
  name: "carddetail",
  data: () => ({
         crossChain:false,
    addressExploer:'',
    	isshowContract:false,
    startTimer:'',
    crossChianList:[],
				endTimer:'',
     OpenQrCode:false,
     OpenWithDraw:false,
    Appeal: false,
    Banprompt:false,
    currfriend: {},
    pageData: {},
    opensale: false,
    crossSuccess:false,
    headers: [
      { text: "Event", value: "event", sortable: false },
      { text: "Form", value: "form", sortable: false },
      { text: "To", value: "to", sortable: false },
      { text: "Transcation", value: "transcation", sortable: false },
      { text: "Time", value: "time", sortable: false },
    ],
    PropertyList:[
    

    ],
    desserts: [],
    openShare: false,
    openmsg: false,
    opentrans: false,
    isOnsale: true,
    IpfsHub: "",
  IpfsMetadata:"",
    sharePath: "",
    nftAddress: "",
    tokenid: "",
    isShowFalse: false,
    isShowSuccess: false,
    isShowDelistSuccess: false,
    isShowDelistFalse: false,
    falseTitle: "reslse filed",
    successTitle: "resale success",
    delistSuccessTitle: "delist success",
    delistFalseTitle: "delist filed",
    openLargemg: false,
    contractId: "",
    logoimg,
    assetId: "",
    Sold: false,
   openputonsale:false,
   now: "",
   start: "",
   end: "",
   insure: "",
   day: 0,
   hour: 0,
   minutes: 0,
   second: 0,
   seconds: "",
   hh: 0,
   mm: 0,
   ss: 0,
   moreseconds: "",
   openaban: false,
   auctionNo:'',
   auctionStatus:'',
   address:'',
   imgUrl:'',
   name:'',
   constructID:'',
   onSaleTokenIds:'',
   offSaleTokenIds:'',
   supplyLimit:'',
   nftType:'',
   avliableLenth:0,
   Total:false,
  isShowAva:false,
  Burning: false,
  Transfer: false,
  isShowHover:true,
  isshowLincense:false,
  show:false


  }),

  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  filters: {
    emptystr: function (value) {
      if (!value || value.indexOf("NaN") >= 0) return " ";
      return value;
    },
    didfilter: function (value) {
      if (value && value.length > 24) {
        return value.substr(0, 12) + "..." + value.substr(-12);
      } else {
        return "";
      }
    },
  },
  async mounted() {
      //  this.Time(); //调用定时器
    await this.initData();
     window.eventBus.$on('collectionPay', this.collection);
     window.addEventListener("click", this.clickOther);

			
  },
  methods: {
    toMarketplacePage(address){
				//https://testweb.testnet.uptick.network/otherpage?did=0x12c27d405bee4d48cf8ceee24e52b84649a2ab2f
      window.open('https://uptick.upticknft.com/otherpage?did='+address, "_blank");

    },
     CrossChainBtn(){
        this.$gtag.event('跨链操作', { 'event_category': 'Click', 'event_label': '跨链操作' })
        this.crossChain = true
      },
       OpenCrossChain(e){
         
        this.crossChain = e
        // if(!e){
        //      this.crossSuccess = true
        // }
      },
      OpenCrossSuccess(e){
        this.crossSuccess =e
      },
      getTime() {
        if(this.pageData.auctionVo){
        this.now = Date.parse(new Date());
        this.start = this.pageData.auctionVo.startTime;
        this.end = this.pageData.auctionVo.endTime;
        // this.start = Date.parse(new Date("2022-01-10 14:18:00"));
        // this.end = Date.parse(new Date("2022-01-10 14:19:00"));
        this.insure = 1000 * 60 * 60 * 48 + this.end;//48小时 暂时1小时

        if (this.pageData.auctionVo.auctionStatus == 4 || this.pageData.auctionVo.auctionStatus == 0 || this.pageData.auctionVo.auctionStatus == 1) {
          if(this.pageData.auctionNoBid&&this.pageData.auctionNoBid==true){
         this.insure = 1000 * 60 * 60 * 1 + this.end;//1小时 流拍1小时
      }
     
      
      if (this.now < this.start) {
            this.seconds = (this.start - this.now) / 1000;
          } else if (this.now < this.end) {
            this.seconds = (this.end - this.now) / 1000;
          } else if (this.now < this.insure) {
            this.moreseconds = (this.insure - this.now) / 1000;
          }
        }
        if (this.pageData.auctionStatus == 5) {
          if (this.insure > this.now) {
            this.moreseconds = (this.insure - this.now) / 1000;
          } else {
            this.moreseconds = 0;
          }

        }
        }
      
      },
      // 天 时 分 秒 格式化函数
      countDown() {
        if (this.seconds > 0) {
          let d = parseInt(this.seconds / (24 * 60 * 60));
          let h = parseInt(((this.seconds / (60 * 60)) % 24) + d * 24);
          this.hour = h < 10 ? "0" + h : h;
          let m = parseInt((this.seconds / 60) % 60);
          this.minutes = m < 10 ? "0" + m : m;
          let s = parseInt(this.seconds % 60);
          this.second = s < 10 ? "0" + s : s;
        }
      },
      moreCountDown() {
        if (this.moreseconds > 0) {
          let d = parseInt(this.moreseconds / (24 * 60 * 60));
          let h = parseInt(((this.moreseconds / (60 * 60)) % 24) + d * 24);
          this.hh = h < 10 ? "0" + h : h;
          let m = parseInt((this.moreseconds / 60) % 60);
          this.mm = m < 10 ? "0" + m : m;
          let s = parseInt(this.moreseconds % 60);
          this.ss = s < 10 ? "0" + s : s;
        }
      },
      //定时器没过1秒参数减1
      Time() {
        setInterval(() => {
          if (this.seconds > 0) {
            this.seconds -= 1;
            this.countDown();
          } else if (this.moreseconds > 0) {
            this.moreseconds -= 1;
            this.moreCountDown();
          } else {
            this.getTime();
          }

        }, 1000);
      },
     collection(){
    this.initData()
     },
     moused() {
        console.log("wxl --- 222222")
        this.show = true
      },
      leave() {
        this.show = false
      },
    async initData() {
      debugger
      let params = {
        contractAddress: this.$route.query.contractAddress,
        tokenId: this.$route.query.tokenId,
        owner: this.$store.state.did,
      };
      debugger
      // 如果分享的链接omner不属于自己 ，跳转到商品详情
      let routerOwner = this.$route.query.owner
      console.log('routerOwner',routerOwner);
    if(!params.owner  || ( routerOwner && routerOwner != params.owner )){
      //未登录情况下跳转到商品详情
      // saledetail
      let targetUrl= window.location.href.replace("carddetail","saledetail");
       location.href=targetUrl;
      return
    }
      let res = await api.home.cardDetailInfo(params);
      if(res.code == 10002)
      {
        let targetUrl= window.location.href.replace("carddetail","saledetail").replace("from=personalPage","");
       location.href=targetUrl;
      return
      }
      let ipfsImg = "";

   

 if(!res.data.imgUrl){
   res.data.imgUrl="QmPcXBirGiUSyjb8i2FQUpNDTtPHBsgo7tviwwm4YQeJ6p"
 }
   if(res.data.fileType =='MP4'){
        this.IpfsHub = this.getImageIpfs(res.data.fileUrl);
      }else{
          this.IpfsHub = this.getImageIpfs(res.data.imgUrl);
      }
    ipfsImg = res.data.imgUrl;
        this.sharePath = ipfsImg;
      
          let imgurl =  res.data.imgUrl.indexOf("http")
      
        if(imgurl == -1){
          res.data.imgUrl = await getFileSrc(BANNER_KEY, res.data.imgUrl);
        }
       
      res.data.fileUrl = await getFileSrc(BANNER_KEY, res.data.fileUrl, true);
      res.data.issuerImg = await getFileSrc(BANNER_KEY, res.data.creatorPhoto);
      res.data.issuesTime = this.timestampToDate(res.data.issuesTime);


  
      //    if( res.data.fileType =='MP4'){
      //          this.IpfsHub = res.data.fileUrl;
      // }else{
      //       this.IpfsHub = res.data.imgUrl;
      // }
      this.addressExploer="https://irishub.iobscan.io/#/mto/nft-denoms/"+res.data.contractAddress.toLowerCase()
      let metadataUrl = res.data.contractAddress.indexOf('ibc')
      console.log("metadataUrl ---111",metadataUrl)
      let contarctaddress = res.data.contractAddress
      if(metadataUrl == 0)
       contarctaddress = res.data.contractAddress.replace(
        '/',
        "-"
      ); 
    
      this.IpfsMetadata= await api.image.getIPFSurl(contarctaddress,res.data.tokenId); 
      this.pageData = res.data;
      if(this.pageData.auctionVo == null){
          this.pageData.useType = 0
      }
      
      this.PropertyList = JSON.parse(res.data.properties)
      if(this.PropertyList)
      this.PropertyList = this.PropertyList.filter((item)=>item.trait_type != '' && item.value != '')
      console.log("wxl ---- 77777",this.PropertyList,res.data.properties)
      if(this.pageData.description)
      this.pageData.description = this.pageData.description.replace(
        /\n/g,
        "<br/>"
      ); 
      if(this.pageData.couponTime){
					this.startTimer = this.timestampToDateHour(this.pageData.couponTime.couponStartTime)
					this.endTimer = this.timestampToDateHour(this.pageData.couponTime.couponEndTime)
			}
     if(!this.pageData.creatorName){
       this.pageData.creatorName= this.pageData.creatorOwner.substr(0,6)+"..."+this.pageData.creatorOwner.substr(-6)
     }
      this.nftAddress = this.pageData.nftAddress;
      this.tokenid = this.pageData.tokenId;
       this.auctionNo = this.pageData.auctionNo;
        this.auctionStatus = this.pageData.auctionStatus;
         this.address = this.pageData.contractAddress;
        
       
            this.imgUrl = this.pageData.imgUrl;
            this.name = this.pageData.name;
            this.constructID = this.pageData.id;
            this.onSaleTokenIds = this.pageData.onSaleTokenIds;
            this.offSaleTokenIds = this.pageData.offSaleTokenIds;
            this.supplyLimit = this.pageData.amount;
         
            this.nftType = this.pageData.nftType;
            if (this.pageData.offSaleTokenIds != null ) {
               this.avliableLenth = this.pageData.offSaleTokenIds.length;
            }
            if (this.pageData.onSaleTokenIds != null) {
               this.avliableLenth += this.pageData.onSaleTokenIds.length;
            }
     
            	 // 获取跨链记录
				 let crossChainParams ={
      pageSize: 50,
      pageNumber: 1,
      nftAddress: this.$route.query.contractAddress,
      nftId: this.$route.query.tokenId,
    }
   let crossChianList= await api.home.getCrossListByTokenId(crossChainParams);
   this.crossChianList = crossChianList.data

            let histroyParams = {
               contractAddress: this.$route.query.contractAddress,

            }
            if(this.pageData.collectionType == 1){
              histroyParams.tokenId = this.$route.query.tokenId
				}

     let res2 = await api.home.assetHistory( histroyParams );
      this.desserts = [];
      res2.data.forEach((v) => {
        let from = logoimg;
          let to = logoimg;
          if (v.fromImg) {
            from = this.getImageIpfs(v.fromImg);
          }
          if (v.toImg) {
            to = this.getImageIpfs(v.toImg);
          }
          let fromName,toName;
  
          if (v.fromName != null && v.fromName != '') {
            fromName = v.fromName;
          }else{
            console.log("wxl ----d ererer",v.fromAddress != null)
            if( v.fromAddress != null){
                fromName = v.fromAddress.substr(0, 5)+"..."+v.fromAddress.substr(-5);
            }else{
              fromName ='—'
            }
          
          }
           console.log("wxl ----d ererer",v.toName,v.toName != null)
          if (v.toName != null && v.toName != '' ) {
            toName = v.toName;
          }else{
            if(v.toAddress != null){
             toName =  v.toAddress.substr(0, 5)+"..."+v.toAddress.substr(-5);
            }else{
              toName ='—'
            }
        
          }
          let price=v.price+" "+v.tokenType
          if(v.price==null){
            price="";
          }
              
          this.desserts.push({
            event: v.event,
            price:price,
            form: fromName,
            to: toName,
            time:this.isMobile ? this.timestampToDate(v.eventTime): this.timestampToDateTime(v.eventTime),
            formavatarSrc: from,
            toavatarSrc: to,
            fromGrade:v.fromGrade,
            toGrade:v.toGrade
          });
      });
        this.Time(); //调用定时器

      // 判断用户是否存在
      let param = {
          userAddress: this.pageData.creatorOwner,
        };
        let ress = await api.home.createInfo(param);
        if(ress.data.createTime == null){
          this.isShowHover = false
        }else{
          this.isShowHover = true
        }
    },
    share,
    shareBtn() {
      this.openShare = true;
    },
    saleBtn() {
      this.opensale = true;
    },
     //销售统计弹层
      saleTotalBtn() {
        this.Total = true;
      },
      openTotal(e) {
        this.Total = e;
      },
             openBanprompt(e){
         this.Banprompt = e
      },
    Open(e) {
      if (e == 1) {
        this.initData();
        this.opensale = false;
      } else {
        this.opensale = e;
      }
    },
     // 结束售卖弹层
      EndSaleBtn() {
        this.Sold = true;
      },
      openSold(e) {
       this.Sold = e;
         
      },
    TransferBtn() {
      this.Transfer = true;
    },
    opentransfer(e) {
      this.Transfer = e;
    },
  // 销毁
burnBtn() {
        this.Burning = true;
      },
openBurn(e) {
        this.Burning = e;
      },
       showQRcode(){
         this.OpenQrCode =true
      },
         openQrCode(e){
         this.OpenQrCode = e
      },
      WithdrawBtn(){
       this.OpenWithDraw = true
      },
      OpenWithDraws(e){
        this.OpenWithDraw = e
      },
      //开始售卖/拍卖弹层
      putOnSaleBtn() {
        let json = localStorage.getItem("key_user_grade");
          let jsonObj = JSON.parse(json)

         if(jsonObj.grade == 2){
               this.Banprompt = true
               console.log("wxl ---- 2222")
            }else{
               this.openputonsale = true;
            }
       
      },
      openPutOnSale(e) {
            this.openputonsale = e;
         
      },
       AbanBtn() {
        this.openaban = true;
      },
      openAban(e) {
        this.openaban = e;
      },
        mousedCreate(){
        if(this.isShowHover)
        this.isShowAva = true
      },
      		mousedContract(){
      this.isshowContract = true

    },
    leaveContract(){
      this.isshowContract = false
    },
    
      clickOther(){
        this.isShowAva = false
        this.openShare = false
      },
    async offSaleBtn() {
      let param = {
        assetId: this.pageData.id,
      };

      let res = await api.home.nftSingleDeList(param);
      if (res.success == true) {
        this.pageData.saleStatus = "0";
        this.isShowDelistSuccess = true;
        this.delistSuccessTitle = this.$t(res.msg);
      } else {
        this.isShowDelistFalse = true;
        this.falseTitle = this.$t(res.msg);
      }
    },
    async offSaleBtnOld() {
      let denomId = this.nftAddress;
      let sender = this.$store.state.did;
      let nftIds = [];
      nftIds.push(this.tokenid);

      if (this.$chainName == "UPTICK EVM") {
        this.$wallet.setContractAddress(this.pageData.nftType, {
          address: this.pageData.nftAddress,
          platformAddress: this.pageData.platformAddress,
        });
        let result = await this.$wallet.revokeApprovesWithArray(
          this.pageData.nftType,
          nftIds,
          this.pageData.price,
          [this.assetId]
        );
        if (result.hash) {
          this.isShowSuccess = true;
          this.falseTitle = this.$t(res.data.msg);
        } else {
          this.isShowFalse = true;
          this.falseTitle = this.$t(res.data.msg);
        }
        return;
      }

      let param = {
        callBack:
          `${this.$env.VUE_APP_API_URL}` +
          "/api/3.0/callback/offsale?&contractAddress=" +
          denomId +
          "&owner=" +
          sender,
        denom: denomId,
        tokenIds: nftIds,
        owner: sender,
      };

      let res = await api.home.Saleout(param);
      if (res.data.success == true) {
        this.isShowSuccess = true;
        this.falseTitle = this.$t(res.data.msg);
      } else {
        this.isShowFalse = true;
        this.falseTitle = this.$t(res.data.msg);
      }
    },
    msgBtn() {
      this.currfriend = {
        fansName: this.pageData.creatorName,
        fansAddress: this.pageData.creator,
      };
      this.openmsg = true;
    },
    //弹框关闭后获取open值
    OpenMsg(e) {
      this.openmsg = e;
    },
    toPage() {
      if (this.pageData.creator == this.$store.state.did) {
        this.$router.push({
          name: "PersonalPage",
          query: { did: this.pageData.creator },
        });
      } else {
        this.$router.push({
          name: "Otherpage",
          query: { did: this.pageData.creator },
        });
      }
    },
    timestamptitleTime(time) {
      let date = new Date(time);
      let Y = date.getFullYear() + ".";
      let M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + ".";
      let D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      let strDate = Y + M + D;
      return strDate;
    },
    //查看大图
    openLargeImg() {
      this.openLargemg = true;
    },
    //弹框关闭后获取open值
    closeLargeImg(e) {
      this.openLargemg = e;
    },
         mousedlincense(){
            this.isshowLincense = true
      },
    leavelincense(){
      this.isshowLincense = false
    },
    //申诉弹层
      AppealBtn() {
        console.log("wxl -- dewew")
        this.Appeal = true;
      },
      openAppeal(e) {
        this.Appeal = e;
      },
  },
};
</script>

<style lang="scss" scoped>

.tab_tit{
	font-weight: bold;
				
				font-size: 25px;
				font-weight: bold;
				font-stretch: normal;
				letter-spacing: 0px;
				color: #270645;
	
				display: block;
}
 .explaincontract{
      width: 130px;
      height: 40px;
      background-color: #270645;
      border-radius: 3px;
      position: absolute;
      top: 25px;
    left: 340px;
      z-index: 1;
      span {
        margin: auto;
        font-family:Helvetica;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 15px;
        letter-spacing: 0px;
        color: #ffffff;
        margin-left: 13px;
        margin-right: 13px;
      }

    }
.elevation-1 {
  border-bottom: 1px solid #eee;
}

.table ::v-deep .elevation-1 {
  height: 51px;
  box-shadow: 2px 2px 5px #ccc !important;
  background-blend-mode: normal, normal;
  border-radius: 5px;
}

.pointer {
  cursor: pointer;

  img {
    width: 42px;
    height: 42px;
    border-radius: 50%;
  }
}

.contain {
  margin: 0;
  padding: 0;

  .card_img {
    /*width: 1221px;*/
    /*height: 611px;*/
    background-color: #f8f6fd;

    img {
      display: block;
      width: 100%;
      height: 610px;
      margin: 0 auto;
      object-fit: contain;
    }

    .status {
      float: right;
      position: relative;
      right: 0;
      bottom: 56px;

      img {
        width: 85px;
        height: 85px;
      }

      .ban {
        width: 25px;
        height: 25px;
        display: block;
        position: absolute;
        top: 50px;
        left: -34px;
      }
    }
  }

  .cirtile {
    /*width: 748px;*/
    .sharestyle {
      position: relative;
      .tit {
        font-family: Helvetica;
        font-weight: bold !important;
        font-size: 40px;
        font-weight: bold;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #270645;
      }
      .share {
        width: 166px;
        height: 161px;
        background-image: linear-gradient(#ffffff, #ffffff),
          linear-gradient(#6f58d9, #6f58d9);
        background-blend-mode: normal, normal;
        box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
        border-radius: 5px;
        position: absolute;
        right: 25px;
        top: 0px;
        z-index: 20000;

        img,
        .icon {
          display: inline-block;
          width: 25px;
          height: 25px;
        }

        span {
          font-weight: bold;
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #270645;
        }
      }
    }
    .cont,
    .link {
      font-family: Helvetica;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
      width: 722px;
    }
    .title{
      font-family: Helvetica;
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
    }
    .lincenseimg{
        position: relative;
        img{
      
          height: 30px;
        }
         .explainlincense{
      width: 330px;
      height: 100px;
      background-color: #270645;
      border-radius: 3px;
      position: absolute;
      top: 35px;
      // left: 25px;
      z-index: 1;
      span {
        margin: auto;
        font-family:Helvetica;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 15px;
        letter-spacing: 0px;
        color: #ffffff;
        margin-left: 13px;
        margin-right: 13px;
      }

    }
  }
    .Properties{
      width: 185px;
      font-family: Helvetica;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
    }
    .PropertiesValue{
      font-family: Helvetica;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
    }
    .NftID{
         font-family: Helvetica;
      font-size: 14px;
    }
    .createName {
      font-family: Helvetica;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
      span {
          cursor: pointer;  
        color: #7800f4;
      }
      
      .name{
        	cursor: pointer;		
						height: 30px;
						overflow:hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow:ellipsis;
						font-family: Helvetica;
						font-size: 14px;
						font-weight: normal;
						font-stretch: normal;
						letter-spacing: 0px;
						color: #7800f4;
						padding-left: 4px;
      }
    }

    .views {
      a {
        text-decoration: none;
      }

      img {
        width: 30px;
        height: 30px;
      }

      .mt-1 {
        font-weight: bold;
      }
    }
    .preferentialInfo{
			margin-left: 44px;
      margin-top: -10px;
					.img{
					
					}
					.contant{
						margin-left: 10px;
						.time{
							font-family: Helvetica;
							font-size: 13px;
							font-weight: bold;
							font-stretch: normal;
							letter-spacing: 0px;
							color: #270645;

						}
						.des{
							font-family: Helvetica;
							font-size: 13px;
							font-weight: normal;
							font-stretch: normal;
							line-height: 20px;
							letter-spacing: 0px;
							color: #766983;

						}
					}

				}
    .avaiable {
      font-family: Helvetica;
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      color: #270645;
      .irisOriginalPric{
        margin-left: 20px;
        text-decoration: line-through;
					font-family: "\5FAE\8F6F\96C5\9ED1";
					font-size: 15px;
					font-weight: normal;
					font-stretch: normal;
					letter-spacing: 0px;
					line-height: 15px;
					color: #766983;
      }
    }
    .footor {
      .sub {
        .text--sub-title{
          font-size: 15px;
          font-weight: bold;
        }
        .text--number{
           font-size: 15px;
          font-weight: bold!important;
        }
        .sale {
          background-color: #7800f4 !important;
          width: 100%;
          height: 100%;
          border: none;
          border-radius: 20px;
          background: #fff;
          font-family: Helvetica;
          font-weight: bold;
          font-size: 15px;
          font-weight: bold;
          font-stretch: normal;
          letter-spacing: 0;
          margin-top: -10px;
        }
        .btnbox {

  height: 41px;
  box-sizing: border-box;
  padding: 2px;
  background-image: linear-gradient(
          0deg,
          #d202fd 0%,
          #a538fe 26%,
          #776eff 51%,
          #01facd 100%
  );
  
  
  // background-color: #7800f4;
  border-radius: 20px;


  .rd {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 17px;
    background: #fff;
    font-family: Helvetica;
    font-weight: bold;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    letter-spacing: 0;
    color: #270645;
  }
}
   .satrtsale {
        width: 106px;
        height: 41px;
        background-color: #7800f4 !important;
        border-radius: 20px;
        font-family: Helvetica;
        font-weight: bold;
        font-size: 15px !important;
        font-weight: bold;
        font-stretch: normal;
        line-height: 100px;
        letter-spacing: 0px;
        color: #ffffff;
        text-align: center;
}
  .withdraw{
        width: 91px;
        height: 28px;
        background-color: #7800f4 !important;
        border-radius: 20px !important;
        font-family: Helvetica;
        font-weight: bold;
        font-size: 15px !important;
        font-weight: bold;
        font-stretch: normal;
        line-height: 100px;
        letter-spacing: 0px;
        color: #ffffff;
        text-align: center;
        border-radius: 17px;
        margin-top:10px;
  }
  .sold_out {
  width: 170px;
  height: 30px;
  margin-top: -10px;
  background-blend-mode: normal, normal;
  border-radius: 18px;
  font-family: Helvetica;
  font-weight: bold;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  line-height: 30px;
  letter-spacing: 0px;
  color: #270645;
}
      .explain {
          width: 185px;
          height: 56px;
          background-color: #270645;
          border-radius: 3px;
          position: absolute;
          top: 956px;
          left:108px;
          z-index: 2;

          span {
            font-family: Helvetica;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            line-height: 15px;
            letter-spacing: 0px;
            color: #ffffff;
            margin-left: 13px;
            margin-right: 13px;
          }
  }
      }
    }
  }

  .btn_box {
    margin-left: 126px;
    width: 850px;

    .ava {
      max-height: 42px;

      .name {
        display: block;
        font-family: Helvetica;
        font-weight: bold;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #270645;
      }

      .time {
        font-family: Helvetica;
        font-size: 11px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #766983;
      }

      .msg {
        width: 25px;
        height: 24px;
      }

      .share {
        width: 166px;
        height: 161px;
        background-image: linear-gradient(#ffffff, #ffffff),
          linear-gradient(#6f58d9, #6f58d9);
        background-blend-mode: normal, normal;
        box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
        border-radius: 5px;
        position: absolute;
        right: 180px;
        top: 730px;

        img,
        .icon {
          display: inline-block;
          width: 25px;
          height: 25px;
        }

        span {
          font-family: Helvetica;
          font-weight: bold;
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #270645;
        }
      }
    }

    .views {
      a {
        text-decoration: none;
      }

      img {
        width: 30px;
        height: 30px;
      }

      .mt-1 {
        font-weight: bold;
      }
    }

    .btn {
      text-align: left;

      button {
        width: 106px;
        height: 41px;
        background-color: #7800f4;
        border-radius: 20px;
        font-family: Helvetica;
        font-weight: bold;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 41px;
        letter-spacing: 0px;
        color: #ffffff;
      }
    }
  }

  .table {
    margin-bottom: 84px;

    .tab_tit {
      font-family: Helvetica;
      font-weight: bold !important;
      font-size: 25px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;

      .table_item_font {
        .item_fonts {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          width: 150px;
        }
      }
    }
  }

  &.mobile {
    .auction-status {
      // display: flex;
      flex-direction: column !important;
      .ml-6 {
        margin-left: 0px !important;
      }
    }
    .NftID {
      word-break: break-all;
    }
    .card_img {
      width: 100%;

      .sizeimg {
        width: 100%;
        height: auto;
      }

      .status {
        float: right;
        position: relative;
        right: 0;
        bottom: 65px;

        img {
          width: 85px;
          height: 85px;
        }

        .ban {
          width: 25px;
          height: 25px;
          display: block;
          position: absolute;
          top: 50px;
          left: -34px;
        }
      }
    }

    .cirtile {
      .cont,
      .link {
        max-width: 100%;
      }
    }

    .card_info {
      .sharestyle {
        margin-top: 20px;
      }
      .btn_box {
        max-width: 100%;

        .ava {
          .share {
            width: 166px !important;
            height: 161px;
            background-image: linear-gradient(#ffffff, #ffffff),
              linear-gradient(#6f58d9, #6f58d9);
            background-blend-mode: normal, normal;
            box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
            border-radius: 5px;
            position: relative;
            right: 150px;
            top: 50px;

            img,
            .icon {
              display: inline-block;
              width: 25px;
              height: 25px;
            }

            span {
              font-family: Helvetica;
              font-weight: bold;
              font-size: 13px;
              font-weight: normal;
              font-stretch: normal;
              letter-spacing: 0px;
              color: #270645;
            }
          }
        }
      }
    }

    .table {
      margin-bottom: 100px;

      .table_font {
        font-weight: bold;
        font-family: Helvetica;
        font-size: 14px;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #270645;
      }

      .table_item_font {
        .item_font {
          font-weight: normal;
          font-family: Helvetica;
          font-size: 14px;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #270645;
          display: flex;
          align-items: center;
        }

        .item_fonts {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          width: 10px;
        }
        .v-avatar {
          margin-top: 20px;
        }
      }
    }
  }
}

.sizeimg {
  object-fit: contain;
}

.video {
  max-height: 610px;
  max-width: 100%;
  width: 100%;
  height: 100%;
  margin: auto;
  object-fit: contain;
  align-items: center;
  /*垂直居中*/
  justify-content: center;
  /*水平居中*/
}
</style>

